import { createApp } from 'vue'
import App from './App.vue'
import { router } from './router'
import { VueCookieNext } from 'vue-cookie-next'

import "@/assets/normalize.css";
import "@fortawesome/fontawesome-free/js/all";

createApp(App).use(router).use(VueCookieNext).mount('#app')

VueCookieNext.config({
    path: '/',
    expires: '1d',
    domain: '',
    secure: true,
    sameSite: 'None',
    httpOnly: true,
})