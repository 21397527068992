<template>
  <router-view></router-view>
  <Footer></Footer>
</template>

<script>
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Footer
  }
}
</script>

<style>
#app {
  height: 100%;
  font-family: Whitney,"Helvetiga Neue",Helvetica,Arial,sans-serif;
}
</style>