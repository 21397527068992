import {createRouter, createWebHistory} from 'vue-router'
import axios from 'axios';

const routes = [
    {
        path: '/auth',
        name: 'Auth',
        component: () => import('../views/Auth')
    },
    {
        path: '/return',
        name: 'Return',
        component: () => import('../views/Return')
    },
    {
        path: '/',
        name: 'Login',
        component: () => import('../components/LoginForm')
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('../views/Dashboard'),
        children: [
            {
                path: 'home',
                name: 'Home',
                component: () => import('../components/dashboard/Home.vue')
            },
            {
                path: 'queue',
                name: 'Queue',
                component: () => import('../components/dashboard/Queue.vue')
            },
            {
                path: 'players',
                name: 'Players',
                component: () => import('../components/dashboard/Players.vue')
            },
            {
                path: 'leaderboard',
                name: 'Leaderboard',
                component: () => import('../components/dashboard/Leaderboard.vue')
            },
            {
                path: 'diagnostics',
                name: 'Diagnostics',
                component: () => import('../components/dashboard/Diagnostics.vue')
            },
            {
                path: 'logs',
                name: 'Logs',
                component: () => import('../components/dashboard/Logs.vue')
            },
            {
                path: 'settings',
                name: 'Settings',
                component: () => import('../components/dashboard/Settings.vue')
            },
            {
                path: 'staff',
                name: 'Staff',
                component: () => import('../components/dashboard/Staff.vue')
            },
        ]
    }
]

export const router = createRouter({
    mode: 'history',
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

router.beforeEach(async (to, from, next) => {
    const isAuthenticated = await axios.get(`${process.env.VUE_APP_APIURL}/checkauth`, { withCredentials: true, headers: {'Content-Type': 'application/json'} }).then(res => {
    if (res.data) {
            return true;
        } else {
            return false;
        }
    })
    if (to.name === "Auth" || to.name === "Return") {
        next();
    } else if (isAuthenticated && to.name === ("Login" || "Auth" || "Return")) {
        next({
            name: "Dashboard"
        });
    } else if (!isAuthenticated && to.name !== "Login") {
        next({
            name: 'Login'
        })
    } else if (isAuthenticated && to.name === "Dashboard") {
        next({
            name: "Home"
        })
    } else if (isAuthenticated && to.name === ("Home" || "Queue" || "Players" || "Leaderboard" || "Diagnostics" || "Logs" || "Settings" || "Staff")) {
        next();
    } else {
        next();
    }
})