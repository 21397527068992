<template>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      details: `${process.env.VUE_APP_NAME} v${process.env.VUE_APP_VERSION}`,
    };
  },
};
</script>

<style scoped>
</style>